.loading {
  height: 300px;
  overflow: hidden;
  text-align: center;
  align-items: center;

  color: #0057b8;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loading img {
  height: 90px;
  margin: -20px auto 10px;

  -webkit-animation: loading 1.2s linear infinite;
  -moz-animation: loading 1.2s linear infinite;
  -ms-animation: loading 1.2s linear infinite;
  -o-animation: loading 1.2s linear infinite;
  animation: loading 1.2s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotateZ(0deg);
    left: 0px;
    top: 0px;
  }
  25% {
    transform: rotateZ(-45deg);
    left: 200px;
    top: 0px;
  }
  50% {
    transform: rotateZ(0deg);
    left: 200px;
    top: 200px;
  }
  75% {
    transform: rotateZ(45deg);
    left: 0px;
    top: 200px;
  }
  100% {
    transform: rotateZ(0deg);
    left: 0px;
    top: 0px;
  }
}
