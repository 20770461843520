@font-face {
  font-family: 'GilroyBold';
  src: local('GilgroyBold'), url('../../public/fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'GilroyMedium';
  src: local('GilgroyMedium'), url('../../public/fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: 'PoppinsBold';
  src: local('PoppinsBold'), url('../../public/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'PoppinsLight';
  src: local('PoppinsLight'), url('../../public/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: 'PoppinsSemiBold';
  src: local('PoppinsSemiBold'), url(../../public/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'PoppinsRegular';
  src: local('PoppinsRegular'), url(../../public/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

.GilroyBold {
  font-family: 'GilroyBold' !important;
}

.GilroyMedium {
  font-family: 'GilroyMedium' !important;
}

.PoppinsBold {
  font-family: 'PoppinsBold' !important;
}

.PoppinsSemiBold {
  font-family: 'PoppinsSemiBold' !important;
}

.PoppinsRegular {
  font-family: "PoppinsRegular" !important;
}

.PoppinsLight {
  font-family: 'PoppinsLight' !important;
}
